/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sn-youtube': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.451.698C13.256.238 11.11.018 9 0 6.89.018 4.744.239 3.568.68 1.39 1.51.67 3.677.52 4.226l-.008.028a8.37 8.37 0 00-.023.09l-.032.14a18.33 18.33 0 00-.455 4.352c-.018 1.342.085 2.835.44 4.319a17.913 17.913 0 00.093.364c.18.623.921 2.686 3.033 3.492 1.176.441 3.323.662 5.432.68 2.11-.018 4.256-.22 5.432-.68 2.176-.83 2.897-2.996 3.048-3.546l.008-.028a9.173 9.173 0 00.055-.23c.35-1.5.473-3.013.455-4.352.018-1.353-.087-2.861-.433-4.341l-.036-.15a7.825 7.825 0 00-.024-.096l-.01-.036c-.157-.567-.883-2.709-3.044-3.534z" _fill="#898B9B"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M7.793 10.567v-3.31c1.195.553 2.121 1.087 3.216 1.663-.903.492-2.02 1.045-3.216 1.647zm6.662-4.955c-.207-.267-.558-.475-.932-.544-1.1-.205-7.96-.206-9.06 0-.3.055-.567.189-.796.397-.967.882-.664 5.612-.431 6.379.098.331.225.57.384.728.206.207.487.35.81.414.906.184 5.57.287 9.074.028.322-.055.608-.203.834-.42.894-.878.833-5.874.117-6.982z" _fill="#fff"/>'
  }
})
